<template>
  <top></top>
  <div id="player_con" v-if="user">
    <div class="fgline"></div>
    <div class="userinfo">
      <div class="myinfo">
        <img
          class="avatar"
          v-bind:src="userInfo.avatar"
        />
        <div class="info">
          <p>
            {{ userInfo.username }}
            <a class="sm" href="/user/idcard"> [实名认证]</a>
          </p>
          <p class="s">ID:{{ userInfo.id }}</p>
          <p class="vip"><i></i><em>VIP0</em></p>
        </div>
        <div class="button pa"><a href="/sign/">签到</a></div>
      </div>
    </div>
    <div class="fgline"></div>
    <div class="box">
      <div class="link clearfix">
        <a href="/me/vip/"><i class="player_1"></i>VIP中心</a>
        <a href="/gametry/"><i class="player_2"></i>试玩中心</a>
        <a href="/score/index"><i class="player_3"></i>积分商城</a>
      </div>
    </div>
    <div class="fgline"></div>
    <a class="myscore"
      >我的积分
      <div>{{ userInfo.integral }}</div></a
    >
    <div class="fgline"></div>
    <a href="javascript:;" class="logout" v-on:click="onLogout">退出登录</a>
    <div class="fgline"></div>
  </div>

  <fnav></fnav>

  <login
    v-on:changeLoginBoxComponent="changeLoginBox"
    v-model:showLoginBox="showLoginBox"
  ></login>
</template>

<script>
import top from "@/components/top.vue";
import fnav from "@/components/fnav.vue";
import login from "@/components/login.vue";

import { getUserInfo } from "@/api/user.js";

export default {
  name: "Userindex",
  components: {
    top,
    fnav,
    login,
  },
  data() {
    return {


      showLoginBox: false,
      user: "",
      userInfo: {},
    };
  },
  methods: {
    changeLoginBox: function (val) {
      this.showLoginBox = false;
      this.loadUserInfo();
    },

    loadUserInfo: async function () {
      try {
        this.user = this.$store.state.user;
        let param = { token: this.user };
        const { data } = await getUserInfo(param);
        // console.log(data.data.data);
        if (data.code != "200") {
          this.$store.commit("setUser", null);
        }
        this.userInfo = data.data;
      } catch (error) {
        console.log(error.response + "???");
      }

      // console.log(this.userInfo);
    },

    // 退出登陆方法
    onLogout() {
      //确认退出 ：
      this.$store.commit("setUser", ''); //就是把数据设置为null ，setUser方法会同时设置vuex和本地存储中的数据

      this.$router.push({ path: "/", query: { user: "out" } });
    },
  },


  created() {
    this.user = this.$store.state.user;

    //console.log(this.$store.state.user);

    if (this.user==null || this.user==false) {
      this.showLoginBox = true;
    } else {
      this.user = this.$store.state.user;
    }

    // console.log(this.user);
    if (this.user) {
      //user 是容器中的数据，如果用户登陆了，证明有user，再进行获取数据
      console.log(this.userInfo);
      this.loadUserInfo();
    }
  },
};
</script>

<style>
</style>