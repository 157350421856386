<template>
  <div id="dialogBox" class="dialog-box" v-show="showDialogBox">
    <div class="dialog">
      <div class="dialog_hd">提示信息</div>
      <div class="dialog_bd">{{ hint }}</div>
      <div class="dialog_ft" v-on:click="dialogBoxRemove">
        <a href="javascript:;">确定{{ showDialogBox }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialogbox",
  props: {},
  data() {
    return {
      hint: "999",
      showDialogBox: false,
    };
  },

  methods: {
    show(hint) {
      this.hint = hint;
      this.showDialogBox = true;
    },
    dialogBoxRemove: function () {
      // document.querySelector("#dialogBox").style.display = "none";
      this.showDialogBox = false;
    },
  },
  mounted() {
    let top = document.body.scrollTop + window.screen.height / 2;
    document.querySelector("#dialogBox").style.top = top + "px";
  },
  setup() {
    return {};
  },
};
</script>

<style>
</style>