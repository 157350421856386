// 用户相关请求模块
import request from '@/utils/request' //导入基础的请求模块
import store from '@/store' // 加载容器模块，后期在request模块中的请求拦截器加了

// 发起登陆请求
export const login = (data) => {

    return request({
        method: 'POST',
        // url: '/app/v1_0/authorizations', //请求的url ，baseUrl 在request中定义了
        url: '/api/login/',
        data: data
    })
}


// 获取用户信息
export const getUserInfo = (data) => {
    return request({
        method: 'POST',
        url: '/api/userinfo',
        data: data,

        // 发送请求头的数据,后期使用请求拦截器统一发送token，在request.js中的请求拦截器中统一加
        // headers: {
        //     Authorization: `Bearer ${store.state.user}` //注意Bearer+空格+token
        // }
    })
}

// 发送验证码请求
// * 注意：每手机号每分钟1次
export const sendSms = function (mobile) {
    return request({ //注意别掉了return，否则接收不到成功的返回值
        method: 'POST', //请求方式
        url: `/api/sms/mobile/${mobile}`
    })
}


// 发起注册请求
export const register = (data) => {

    return request({
        method: 'POST',
        url: '/api/userreg/',
        data: data
    })
}

// 获取用户玩过的游戏
export const usergame = (data) => {
    return request({
        method: 'POST',
        url: '/api/usergame/',
        data: data
    })
}
