<template>
  <div id="loginBox" class="login-box inbox" v-show="showLoginBox">
    <div class="mask"></div>
    <div class="loginwin">
      <img
        src="@/assets/images/close.png"
        class="login-close"
        v-on:click="removeLoginBox"
      />
      <div class="login-head">
        <div id="dlnav" class="dlnav">
          <a
            href="javascript:;"
            id="nav_dl"
            v-bind:class="isreg == false ? 'on' : ''"
            v-on:click="showLoginReg('login')"
            >登录</a
          ><a
            href="javascript:;"
            id="nav_zc"
            v-bind:class="isreg == true ? 'on' : ''"
            v-on:click="showLoginReg('reg')"
            >注册</a
          >
        </div>
      </div>
      <div id="custom-login-box" class="login-info login-animate">
        <!--登录-->
        <form @submit="onSubmit">
          <div class="linput-box" v-show="isreg == false">
            <div class="login-yg first">
              <img src="@/assets/images/icon_2.png" class="login-icon" />
              <input
                id="mobile"
                type="input"
                placeholder="请输入用户名"
                autocomplete="off"
                v-model="user.mobile"
              />
            </div>
            <div class="login-yg first">
              <img src="@/assets/images/icon_3.png" class="login-icon" />
              <input
                id="password"
                type="password"
                placeholder="请输入密码"
                autocomplete="off"
                v-model="user.password"
              /><span class="forgetpwd">忘记密码</span>
            </div>
          </div>
        </form>
        <!--注册-->
        <div class="linput-box" v-show="isreg">
          <div class="login-yg first">
            <img src="@/assets/images/icon_2.png" class="login-icon" /><input
              id="regMobile"
              type="input"
              placeholder="手机号码"
              autocomplete="off"
              v-model="user.mobile"
            />
          </div>
          <div class="login-yg first">
            <img src="@/assets/images/icon_3.png" class="login-icon" /><input
              id="reguserpwd1"
              type="password"
              placeholder="密码"
              autocomplete="off"
              v-model="user.password"
            />
          </div>

          <div class="login-yg first" style="position: relative">
            <img src="@/assets/images/icon_9.png" class="login-icon" />
            <input
              id="phonecode"
              name="phonecode"
              type="input"
              placeholder="短信验证码"
              v-model="user.code"
            />
            <a
              id="getcode"
              class="pa db"
              v-on:click="onSendSms"
              href="javascript:void(1)"
              >{{ this.getcodeFlag ? "获取验证码" : this.times }}</a
            >
          </div>
        </div>

        <div class="login-yg login-btn-div">
          <a
            href="javascript:void(0);"
            class="mYg"
            id="mBtn"
            v-on:click="onSubmit"
            >{{ isreg ? "注册" : "登录" }}</a
          >
          <fieldset id="resetlogin" class="">
            <legend>其他登录方式</legend>
          </fieldset>
        </div>
      </div>
      <div class="login-mode">
        <ol id="login-list">
          <li id="wxQcLogin" title="微信扫码登录" data-did="0">
            <div><img src="@/assets/images/icon_4.png" />微信</div>
          </li>
          <li id="wxLogin" title="微信登录" data-did="0" style="display: none">
            <div><img src="@/assets/images/icon_4.png" />微信</div>
          </li>
          <li
            id="qqLogin"
            title="QQ登录"
            class="qq"
            data-did="2"
            style="display: list-item"
          >
            <div><img src="@/assets/images/icon_5.png" />QQ</div>
          </li>
          <li
            id="sinaLogin"
            title="新浪微博登录"
            class="sina"
            data-did="3"
            style="display: list-item"
          >
            <div><img src="@/assets/images/icon_6.png" />新浪</div>
          </li>
          <li
            id="mobileLogin"
            title="手机登录"
            class="mobile"
            data-did="4"
            style="display: list-item"
          >
            <div><img src="@/assets/images/icon_7.png" />手机</div>
          </li>
        </ol>
      </div>
    </div>
  </div>
  <dialogbox ref="dialog"></dialogbox>
</template>

<script>
import { login, register, sendSms } from "@/api/user";
import dialogbox from "@/components/dialogbox.vue";

export default {
  name: "login",
  components: {
    dialogbox,
  },
  emits: {
    changeLoginBoxComponent: {},
  },
  props: {
    showLoginBox: {},
  },
  data() {
    return {
      isreg: false,
      user: {
        // username: "ceshi", // 用户名
        mobile: "", // 手机号
        password: "123456", //密码
        code: "",
      },
      times: 5,
      getcodeFlag: true, //发送短信
    };
  },
  methods: {
    showLoginReg: function (val) {
      switch (val) {
        case "login":
          this.isreg = false;
          break;
        case "reg":
          this.isreg = true;
          break;
      }
    },
    onSendSms: async function () {
      // let times = 60;
      // let getcodeFlag = true;

      console.log("请求短信");

      if (this.user.mobile == "") {
        this.$refs.dialog.show("手机号码不能为空");

        return false;
      }
      const check = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;

      if (!check.test(this.user.mobile)) {
        // console.log("请输入正确的手机号码！");
        this.$refs.dialog.show("请输入正确的手机号码！");
        return false;
      }

      if (!this.getcodeFlag) {
        return false;
      }
      this.getcodeFlag = false;
      const { data } = await sendSms(this.user.mobile);

      if(data.code==200){
        if(data.data.sms){
          //写入本地Storage
          this.$store.commit("setSms", data.data.smstoken);
        }

      }

      console.log(data);

      this.timer = setInterval(() => {
        this.times--;
        if (this.times === 0) {
          this.getcodeFlag = true;
          this.times = 5;
          clearInterval(this.timer);
        }
      }, 1000);
    },

    removeLoginBox: function () {
      // document.getElementById("#loginBox").style.display="none";
      document.querySelector("#loginBox").style.display = "none";
    },

    onSubmit: async function () {
      if (this.isreg) {
        console.log("开始注册");

        // this.$toast.

        if (this.user.code == "") {
          // console.log("验证码不能为空");
          this.$refs.dialog.show("验证码不能为空");
          return false;
        }

        if (this.user.mobile == "") {
          // console.log("手机号码不能为空");
          this.$refs.dialog.show("手机号码不能为空");
          return false;
        }
        const check = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;

        if (!check.test(this.user.mobile)) {
          // console.log("请输入正确的手机号码！");
          this.$refs.dialog.show("请输入正确的手机号码！");

          return false;
        }

        try {
          const user = this.user;
          user.smstoken=this.$store.state.sms;
          const { data } = await register(user);
          console.log(data);
          if (data.code == "200") {
            //console.log("注册成功");
            this.$refs.dialog.show("注册成功,请重新登陆");
            
          }
        } catch (error) {
          console.log(error);
          console.log("注册失败");
        }
      } else {
        console.log("登录");
        try {
          const user = this.user;
          const { data } = await login(user);


          if (data.code == "200") {
            console.log("登陆成功", data);
          this.$store.commit("setUser", data.data.token);
            this.$router.push({ path: "/user", query: { user: "login" } });
            // this.$router.push({path:'/',query:{user: 'login'}})
            this.$emit("changeLoginBoxComponent", false);
            
  
          } else {
            // console.log("登陆失败，请稍后重试1");
            this.$refs.dialog.show(data.msg);
          }
        } catch (error) {
          console.log(error);
          // console.log("登陆失败，请稍后重试2");
          this.$refs.dialog.show("登陆失败，请稍后重试2");
        }
      }
    },
  },
  setup() {
    return {};
  },
};
</script>

<style>
</style>